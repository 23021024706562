<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Fire Suppression" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Proper fire suppression can be a key component in the protection of
            personnel, equipment, and facilities from fire hazards in the area
            or surrounding areas. SMS has applied risk assessment methodologies
            to help design and verify fire suppression systems.
          </p>
          <p>
            Fire suppression analysis includes an assessment/ qualitative
            hazards evaluation of the nozzles, valves, pull stations, etc. The
            analysis also includes on-site inspection of the deluge system,
            inspection of the deluge system UV detectors, deluge (heads) valves,
            discharge nozzles, solenoid valves, pull stations, and associated
            controls. Investigation/ verification of interfaces between the fire
            suppression system and the protected systems is also performed. A
            summary of fire suppression guidelines and requirements is also
            performed based on the DOD Contractor’s Safety Manual (DOD
            4145.26-M), NFPA 15, AMC-R 385-100, and MIL STD 1008.
          </p>
          <p>
            Following the analysis and on-site visit, SMS will assign a
            hazardous risk category to each failure cause identified. The hazard
            category consists of a number and letter designation that when
            combined defines the degree of risk and the frequency of occurrence
            associated with the failure (reference MIL-STD-882E).
          </p>
          <p>
            When deficiencies are found, Safety Action Requests (SARs) will be
            issued during the analysis process. The SAR allows for work on the
            recommendation before the formal report is published. It also
            provides a system to identify progress and documents the corrective
            action and final closeout.
          </p>
          <p>
            At the conclusion of the project, a report is provided that
            documents the identified potential failure scenarios, the risk
            mitigating design features and procedures, the qualitative ranking
            of the failure scenarios, recommendations, and a discussion of
            significant issues.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Fire Suppression",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can assist in analyzing and improving facility fire suppression systems based on regulatory guidelines and requirements, namely DOD Contractor’s Safety Manual (DOD 4145.26-M), NFPA 15, AMC-R 385-100, and MIL STD 1008."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
